import axios from 'axios';
import config from "../lib/config.js";
import { Encryptdata, Decryptdata } from './encryprdata.js';

export const loginAdmin = async (payload) => {
    console.log("admin login ", payload, config.AdminAPI)
    // let data = {"data":payload}
    try {
        var resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/adminlogin`,
            "data": { data: Encryptdata(payload) },
            "headers": {
                "Content-Type": 'application/json'
            },

        })
        console.log("returned response", resp.data)
        let response = Decryptdata(resp.data)
        console.log("resssss", response);
        if (response)

            localStorage.setItem("token", response.token);

        return response;
    }
    catch (err) {
        console.log("err", err)
    }


}
export const getarticle = async () => {
    try {
        var resp = await axios({
            'method': 'GET',
            'url': `${config.AdminAPI}/getarticle`,
        })
        console.log("member resp", resp.data)
        let response = Decryptdata(resp.data)

        return response;
    }
    catch (err) { console.log("err in article list", err) }
}

export const getaboutuser = async () => {
    try {
        var resp = await axios({
            'method': 'GET',
            'url': `${config.AdminAPI}/getaboutuser`,
        })
        console.log("member resp", resp.data)
        let response = Decryptdata(resp.data)

        return response;

    }
    catch (err) { console.log("err in article list", err) }
}

export const customtokenfetch = async (data) => {
    try {
        const resp = await axios({
            'method': 'POST',
            'url': `${config.AdminAPI}/tokenfetchapi`,
            "headers": {
                "Content-Type": 'application/json'
            },

        })
        console.log("returned response", resp.data)
        if (resp.data.token)
            localStorage.setItem("token", resp.data.token);

        return resp.data;
    }
    catch (err) {
        console.log("err", err)
    }
}

export const check = async () => {

    try {
        var data = { "test": "testdata" }
        var resp = await axios({
            "method": "POST",
            'url': `${config.AdminAPI}/checkroute`,
            "data": data,
            "headers": {
                "Content-Type": 'application/json',
                "Authorization": localStorage.getItem("token")
            }
        })

    }
    catch (err) { console.log("err",err) }

}

//roadmap
export const AddKittyRoadmap = async (data) => {
    try {
        console.log("datass",data);
        const resp = await axios({
            method: 'post',
            url: `${config.AdminAPI}/AddKittyRoadmap`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: { data: Encryptdata(data) },
        })
        console.log(resp.data);
        let response = Decryptdata(resp?.data)
        return response;
    } catch (error) {
        console.log("err",error)
    }
 
}

export const EditKittyRoadmap = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${config.AdminAPI}/EditKittyRoadmap`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: { data: Encryptdata(data) },
        })
        console.log(resp.data);
        let response = Decryptdata(resp?.data)
        return response;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const roadmaplist = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${config.AdminAPI}/roadmaplist`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: { data: Encryptdata(data) },
        })
        console.log(resp.data);
        let response = Decryptdata(resp?.data)
        return response;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

export const hideshowroadmap = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${config.AdminAPI}/hideshowroadmap`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: { data: Encryptdata(data) },
        })
        console.log(resp.data);
        let response = Decryptdata(resp?.data)
        return response;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

//whitelist
export const getwhitelistuser  = async()=>{
    
    try{
        var resp = await axios({
          
                'method':'GET',
                'url':`${config.AdminAPI}/getwhitelist`,
                // "headers": {
                //     "Content-Type": 'application/json'
                // },
                // data: { data: Encryptdata(data) },
        })
        console.log("tokonwer resp",resp.data[0])
        let response = Decryptdata(resp?.data)

        return response;
    }
    catch(err){console.log("err in gettok owner",err)}
  }

  export const blockUnblock = async (data) => {
    try {
        const resp = await axios({
            method: 'post',
            url: `${config.AdminAPI}/whitelistblock`,
            "headers": {
                "Content-Type": 'application/json'
            },
            data: { data: Encryptdata(data) },
        })
        console.log(resp.data);
        let response = Decryptdata(resp?.data)
        return response;
    } catch (error) {
        console.log("err",error)
        
    }
  
}

